import * as React from "react";
import { graphql } from "gatsby";
import { useMemo } from "react";
import { useLanguage } from "../contexts/languageContext";
import LookImageCTA from "../components/LookImageCTA";
import { getLooks } from "../utils/processData";
import {
  ActiveFilterGroup,
  FilterGridWrapper,
  FilterPageHeader,
  FilterPageSubtitle,
  FilterWrapper,
  PhotoGrid,
  // PlayerGrid,
} from "../styles/filter";
import ActiveFilterPanel from "../components/ActiveFilterPanel";
import every from "lodash/every";
// import SpotifyPlayer from "../components/SpotifyPlayer";
import StickySideBar from "../components/StickySideBar";
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group";
import Loading from "../components/Loading";
import uniqBy from 'lodash/uniqBy'

const timeout = 500;
const getTransitionStyles = {
  entering: {
    position: `absolute`,
    opacity: 0,
  },
  entered: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 1,
  },
  exiting: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 0,
  },
};

const FilterPageTemplate = ({ data, pageContext }) => {
  const [pageLoaded, setPageLoaded] = React.useState(false);

  React.useEffect(() => {
    setPageLoaded(true);
  }, []);

  const { lang, filters, theme, setFilteredLooks } = useLanguage();

  const { looks, copies, generalCopies } = useMemo(() => {
    return {
      looks: uniqBy(getLooks(data?.prismicDataLooksByRegion.data), p => p.lookid),
      copies: data?.allPrismicCopiesFilter.nodes?.find((h) => h.lang === lang)
        ?.data,
      generalCopies: data?.allPrismicCopiesGeneral?.nodes?.find(
        (h) => h.lang === lang
      )?.data,
      spotifyUrl: data?.prismicDataLooksByRegion.data.spotify_playlist,
    };
  }, [data, lang]);
  const filteredLooks = useMemo(() => {
    const allLooks = looks ? looks : [];
    const allFilters = Object.keys(filters).reduce((a, c) => {
      if (!filters[c]) return a;
      return [...a, filters[c]];
    }, []);

    const fullList = allLooks.filter((look) => {
      if (!look.tags) return false;
      const tags = look.tags.map((t) => t.tag);
      if (!allFilters.length) return true;
      return every(allFilters, (p) => tags.includes(p));
    });
    return fullList;
  }, [looks, filters]);

  React.useEffect(() => {
    setFilteredLooks(filteredLooks)
  }, [setFilteredLooks, filteredLooks])
  // const gridProps = useMemo(() => {
  //   let spotifyLocation = 15;
  //   let total = 10;
  //   let playerSpan = 2;
  //   let height = "auto";

  //   if (isMobile) {
  //     spotifyLocation = 7;
  //     total = 3;
  //     height = "200px";
  //     playerSpan = 3;

  //     // 1 / 1 / span 2 / span 3
  //   } else if (isTablet) {
  //     spotifyLocation = 10;
  //     total = 7;
  //     playerSpan = 3;
  //     // 1 / 3 / span 1 / span 3
  //   }

  //   if (filteredLooks.length < spotifyLocation) {
  //     spotifyLocation = filteredLooks.length;
  //   }
  //   return {
  //     location: spotifyLocation,
  //     total,
  //     span: playerSpan,
  //     listTotal: filteredLooks.length,
  //     height,
  //   };
  // }, [isMobile, isTablet, filteredLooks]);

  if (!pageLoaded) return <Loading progress={0.5} />;
  if (!looks) return null;
  // console.log(
  //   filteredLooks.filter((p) => {
  //     return p.products.filter((l) =>
  //       l.product.document?.data.product_image_kr.url?.includes("_WOMEN_UNDERWEAR_PRODUCT") ||
  //       l.product.document?.data.product_image_kr.url?.includes("_WOMEN_APPAREL") ||
  //       l.product.document?.data.product_image_kr.url?.includes("_MEN_APPAREL") ||
  //       l.product.document?.data.product_image_kr.url?.includes("_MEN_UNDERWEAR_PRODUCT") ||
  //       l.product.document?.data.product_image_kr.url?.includes("_ACCESSORIES_PRODUCT")

  //     ).length > 0;
  //   }).map(p => p.lookid)
  // );
  // console.log(
  //   filteredLooks.filter((p) => {
  //     return !p.looks[0].image.gatsbyImageData
  //   })
  // );

  return (
    <FilterWrapper color={theme.textColor}>
      <StickySideBar copies={generalCopies} />
      <ActiveFilterGroup>
        <FilterPageHeader>{copies.page_title}</FilterPageHeader>
        <FilterPageSubtitle>{copies.page_subtitle}</FilterPageSubtitle>
        <ActiveFilterPanel />
      </ActiveFilterGroup>
      <FilterGridWrapper>
        <TransitionGroup component={PhotoGrid}>
          {/* <ReactTransition
            timeout={{
              enter: 500,
              exit: 500,
            }}
          >
            {(status) => (
              <PlayerGrid {...gridProps} style={getTransitionStyles[status]}>
                <SpotifyPlayer url={spotifyUrl} />
              </PlayerGrid>
            )}
          </ReactTransition> */}

          {filteredLooks.map((look, idx) => (
            <ReactTransition
              timeout={{
                enter: 100,
                exit: 500,
              }}
              key={idx}
            >
              {(status) => (
                <LookImageCTA
                  width={100}
                  look={look}
                  key={idx}
                  style={getTransitionStyles[status]}
                />
              )}
            </ReactTransition>
          ))}
        </TransitionGroup>
      </FilterGridWrapper>
    </FilterWrapper>
  );
};

export const query = graphql`
  query pageQuery(
    $pageIds: [String]
    $regionLooksId: String
    $generalCopiesIds: [String]
    $tagIds: [String]
    $ecommerceLinkIds: [String]
  ) {
    allPrismicDataRegionEcommerce(filter: { id: { in: $ecommerceLinkIds } }) {
      nodes {
        id
        lang
        data {
          region
          links {
            label
            link
          }
        }
      }
    }
    allPrismicCopiesFilter(filter: { id: { in: $pageIds } }) {
      nodes {
        id
        lang
        alternate_languages {
          uid
          type
          lang
        }
        lang
        url
        type
        data {
          page_title
          page_subtitle
          vertical_sticky_label
        }
      }
    }
    allPrismicDataTag(filter: { id: { in: $tagIds } }) {
      nodes {
        id
        data {
          key
          category
          title
        }
      }
    }
    allPrismicCopiesGeneral(filter: { id: { in: $generalCopiesIds } }) {
      nodes {
        id
        lang
        alternate_languages {
          uid
          type
          lang
        }
        lang
        url
        type
        data {
          inspire_me
          about_the_jeans
          filter
          dark_mode
          light_mode
          tag_occasion
          tag_masculine
          tag_feminine
          tag_rather_not_say
          tag_look
          tag_9_to_5
          tag_night_out
          tag_gallery_hopping
          tag_dinner_date
          tag_glamping
          tag_everyday_errands
          tag_attitude
          tag_carefree
          tag_cheerful
          tag_outgoing
          tag_bold
          tag_confident
          tag_chill
          tag_jeans
          tag_90s_straight
          tag_italian_denim
          tag_ultimate_stretch
          clear_all_filters
          meta: meta_filter
        }
      }
    }
    prismicDataLooksByRegion(id: { eq: $regionLooksId }) {
      id
      lang
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      data {
        region
        spotify_playlist
        looks {
          look {
            document {
              __typename
              ... on PrismicLook {
                id
                data {
                  lookid
                  kol
                  attitude
                  looks {
                    image {
                      gatsbyImageData(width: 200)
                      url
                    }
                  }
                  tags {
                    tag
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default FilterPageTemplate;
export { default as Head } from "../components/Head";
