import React from "react";
import styled from "styled-components";
import { useLanguage } from "../contexts/languageContext";

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  // background: ${({ background }) => background};
  z-index: 5;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 100%;
`;
const Loading = ({ progress = 0.1 }) => {
  const { theme } = useLanguage();

  return (
    <Wrapper background={theme.backgroundColor}>
      {Math.floor(progress * 100)}%
    </Wrapper>
  );
};

export default Loading;
