import { Link } from "gatsby";
import React, { useMemo } from "react";
import { useLanguage } from "../contexts/languageContext";
import { getIndividualRoute } from "../routes";
import GatsbyImageComponent from "./GatsbyImage";

const LookImageCTA = ({ look, style }) => {
  const { region } = useLanguage();
  const { image, path } = useMemo(() => {
    return {
      image: (look.looks[0] || {}).image,
      path: `/${region}/${getIndividualRoute(look.lookid)}`,
    };
  }, [look, region]);

  return (
    <Link to={path} style={style}>
      <GatsbyImageComponent gatsbyImageData={image.gatsbyImageData} />
    </Link>
  );
};
export default LookImageCTA;
