import React, { useCallback, useMemo } from "react";
import { useLanguage } from "../contexts/languageContext";
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group";

import { ActiveFilterButton, ActiveFilterPanelWrapper } from "../styles/filter";
import { Icon } from "./CommonIconButton";

const timeout = 250;
const getTransitionStyles = {
  entering: {
    position: `absolute`,
    opacity: 0,
  },
  entered: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 1,
  },
  exiting: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 0,
  },
};

const ActiveFilterPanel = () => {
  const { copies, filters, setFilters, theme } = useLanguage();

  const onSelectKey = useCallback(
    (key, category) => {
      setFilters((prev) => ({
        ...prev,
        [category]: prev[category] === key ? null : key,
      }));
    },
    [setFilters]
  );
  const allFilters = useMemo(
    () =>
      Object.keys(filters).reduce((a, c) => {
        if (!filters[c]) return a;
        return [
          ...a,
          {
            key: filters[c],
            category: c,
          },
        ];
      }, []),
    [filters]
  );

  return (
    <TransitionGroup component={ActiveFilterPanelWrapper}>
      {allFilters.map(({ key, category }) => (
        <ReactTransition
          timeout={{
            enter: timeout,
            exit: timeout,
          }}
          key={key}
        >
          {(status) =>
            copies[`tag_${key}`] ? (
              <ActiveFilterButton
                color={theme.textColor}
                style={getTransitionStyles[status]}
                onClick={() => onSelectKey(key, category)}
              >
                <span>{copies[`tag_${key}`]}</span>
                <Icon name="close" width={20} height={20} alt="close" />
              </ActiveFilterButton>
            ) : null
          }
        </ReactTransition>
      ))}
    </TransitionGroup>
  );
};

export default ActiveFilterPanel;
